// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-massotherapie-js": () => import("./../src/pages/massotherapie.js" /* webpackChunkName: "component---src-pages-massotherapie-js" */),
  "component---src-pages-merci-js": () => import("./../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-offrir-un-soin-js": () => import("./../src/pages/offrir-un-soin.js" /* webpackChunkName: "component---src-pages-offrir-un-soin-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-soins-corporels-js": () => import("./../src/pages/soins-corporels.js" /* webpackChunkName: "component---src-pages-soins-corporels-js" */),
  "component---src-pages-soins-visage-js": () => import("./../src/pages/soins-visage.js" /* webpackChunkName: "component---src-pages-soins-visage-js" */),
  "component---src-pages-tarifs-js": () => import("./../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */)
}

