import React from "react"

const defaultState = {
  show: false,
  toggleShow: () => {},
}

const BookingContext = React.createContext(defaultState)

class BookingProvider extends React.Component {
  state = {
    show: false,
  }

  toggleShow = () => {
    let show = !this.state.show
    this.setState({ show })
  }

  render() {
    const { children } = this.props
    const { show } = this.state
    return (
      <BookingContext.Provider
        value={{
          show,
          toggleShow: this.toggleShow,
        }}
      >
        {children}
      </BookingContext.Provider>
    )
  }
}

export default BookingContext

export { BookingProvider }
